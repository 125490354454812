import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

function ArticleTemplate({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout homeLinks>
      <Seo />
      <div className="post-page">
        <div className="container px-4 mx-auto md:px-0">
          <div className="py-12">
            <h1 className="mb-4 font-normal text-center">
              {frontmatter.title}
            </h1>
            <div className="mx-auto max-w-screen-md">
              <h6 className="mb-4 font-normal text-center breadcrumbs">
                <Link
                  className="font-medium text-coupa-blue bg-none hover:text-coupa-blue-light focus:text-coupa-blue-light"
                  to="/"
                >
                  Home
                </Link>
                &nbsp;/&nbsp;
                <Link
                  className="text-coupa-blue bg-none hover:text-coupa-blue-light focus:text-coupa-blue-light"
                  to="/articles"
                >
                  Articles
                </Link>
                &nbsp;/&nbsp;
                {frontmatter.title}
              </h6>
              <div className="">
                <Img fluid={frontmatter.featuredimage.childImageSharp.fluid} />
                <div className="flex justify-between my-8 date-bar">
                  <h5 className="mb-0 font-normal">
                    Published on {frontmatter.date}
                  </h5>
                  <h5 className="mb-0 font-normal">
                    {console.log(frontmatter.url)}
                    <a
                      href={frontmatter.url}
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium bg-none text-coupa-blue focus:text-coupa-blue-light hover:text-coupa-blue-light"
                    >
                      View on LinkedIn
                    </a>
                  </h5>
                </div>
                <div
                  className="max-w-4xl text-justify"
                  dangerouslySetInnerHTML={{ __html: html }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ArticleTemplate

export const PageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      id
      frontmatter {
        title
        url
        date(formatString: "MMMM DD, YYYY")
        featuredimage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
